import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Layout from "../../components/reuseable/layout";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.svg";
import portal_img from "../../assets/portal.svg";
import { useNavigate } from "react-router-dom";
import HorizontalStepper from "../../components/horizontalStepper";
import { Steps } from "../../types/onboarding";
import AdjustIcon from "@mui/icons-material/Adjust";
import { FormikProps, FormikValues } from "formik";
import { step1Schema, step2Schema, step3Schema } from "./formSchema";
import { useDispatch, useSelector } from "react-redux";
import { selectOnboardingErrors } from "./onboardingSlice";
import { Step1, Step2, Step3 } from "../../components/onboardingSteps";
import {
  useGetProviderDataQuery,
  useSaveStepMutation,
  useSubmitProviderFormMutation,
} from "./onboardingPortalApiSlice";
import AppSnackBar from "../../components/reuseable/appSnackBar";
import AppLoader from "../../components/reuseable/appLoader";
import { getState, setOnboardingStatus } from "../auth/authSlice";

const sideBarData = [
  {
    logo: signup_sidebar_img,
    title:
      "Submit your company information for entry into the DQP Provider Community through the Provider Onboarding Portal.",
    subTitle: [
      "Upon approval, your company information will be entered into our Provider Community and be available to the Sponsors on the DQP Platform. You will also receive an email directing you to the DQP Provider Setup Wizard.",
    ],
  },
  {
    logo: signup_sidebar_img,
    title:
      "Submit your company information for entry into the DQP Provider Community through the Provider Onboarding Portal.",
    subTitle: [
      "Upon approval, your company information will be entered into our Provider Community and be available to the Sponsors on the DQP Platform. You will also receive an email directing you to the DQP Provider Setup Wizard.",
    ],
  },
  {
    logo: portal_img,
    title:
      "Submit your company information for entry into the DQP Provider Community through the Provider Onboarding Portal.",
    subTitle: [
      "Upon approval, your company information will be entered into our Provider Community and be available to the Sponsors on the DQP Platform. You will also receive an email directing you to the DQP Provider Setup Wizard.",
    ],
  },
  {
    logo: portal_img,
    title:
      "Submit your company information for entry into the DQP Provider Community through the Provider Onboarding Portal.",
    subTitle: [
      "Upon approval, your company information will be entered into our Provider Community and be available to the Sponsors on the DQP Platform. You will also receive an email directing you to the DQP Provider Setup Wizard.",
    ],
  },
];
export const baseSteps: Steps[] = [
  {
    name: "step1",
    title: "step 1",
    component: Step1,
    validationSchema: step1Schema,
    icon: AdjustIcon,
    values: {
      ProviderName: "",
      Address: "",
      Address2: "",
      ProviderFileName: "",
      Website: "",
      Country: "",
      State: "",
      Description: "",
      ZipCode: "",
      City: "",
      PhoneNumber: "",
    },
  },
  {
    name: "step2",
    title: "step 2",
    component: Step2,
    validationSchema: step2Schema,
    icon: AdjustIcon,
    values: {
      IsPublic: false,
      Ticker: "",
      YearFounded: "",
      NumberOfEmployees: "",
      Certifications: [],
      OtherCertifications: [],
      Classifications: [],
    },
  },
  {
    name: "step3",
    title: "step 3",
    component: Step3,
    validationSchema: step3Schema,
    icon: AdjustIcon,
    values: {
      PharmaSponsorsServed: "",
      GeographicCoverage: [],
      TherapeuticAreas: [],
      OtherTherapeuticAreas: [],
      ProviderCapabilities: [],
      OtherCapabilitiesOrServices: [],
      ServiceComplianceStatuses: [],
    },
  },
];

export default function OnboardingPortalPage({ currentStepName = "step1" }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(getState);
  const formErrors = useSelector(selectOnboardingErrors);
  const { data: providerData, isLoading, error } = useGetProviderDataQuery({});
  const [saveProviderData] = useSaveStepMutation();
  const [submitProviderData] = useSubmitProviderFormMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(
    baseSteps.findIndex((ele) => ele.name === currentStepName)
  );
  useEffect(() => {
    if (!formErrors.step1) {
      setCurrentIndex(0);
      navigate("/onboarding/step1");
    }
  }, [formErrors, navigate]);

  useEffect(() => {
    if (providerData?.data) {
      if (error && "data" in error) {
        setErrorMessage(error.data.message);
      } else {
        dispatch(
          setOnboardingStatus({
            status: providerData.data.Status,
          })
        );
        if (
          providerData.data?.IsPlatformReady &&
          providerData.data.IsPlatformReady
        ) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("status");
          window.location.replace(
            process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token
          );
        }
        if (
          (providerData?.data.Status ===
          process.env.REACT_APP_USER_ONBOARD_PENDING_REVIEW_STATUS)
        ) {
          navigate("/onboarding/submit-confirmation"); // Navigate to onboarding confirmation page
        }
        if (
         ( providerData?.data.Status ===
          process.env.REACT_APP_USER_ONBOARD_APPROVED_STATUS) || (providerData?.data.Status === "Provider Accepted")
        ) {
          navigate("/wizard"); // Navigate to onboarding confirmation page
        }
      }
    }
  }, [dispatch, error, navigate, providerData, token]);

  const onClickSetIndex = (form: FormikProps<FormikValues>, index: number) => {
    setSuccessMessage("");
    setErrorMessage("");
    saveProviderData(form.values[currentStepName])
      .unwrap()
      .then((payload) => {
        // setSuccessMessage(payload.message);
      })
      .catch((err) => {
        if (err.data) {
          setErrorMessage(err.data.message);
        } else {
          setErrorMessage(err.error);
        }
      });
    setCurrentIndex(index);
  };

  const onClickNext = (form: FormikProps<FormikValues>, name: string) => {
    setSuccessMessage("");
    setErrorMessage("");
    saveProviderData(form.values[currentStepName])
      .unwrap()
      .then((payload) => {
        // setSuccessMessage(payload.message);
      })
      .catch((err) => {
        if (err.data) {
          setErrorMessage(err.data.message);
        } else {
          setErrorMessage(err.error);
        }
      });
    setCurrentIndex(currentIndex + 1);
    navigate(`/onboarding/${baseSteps[currentIndex + 1].name}`);
  };
  const onClickSave = (form: FormikProps<FormikValues>, name: string) => {
    // console.log("Save Clicked", form.values);
    setSuccessMessage("");
    setErrorMessage("");
    saveProviderData(form.values[currentStepName])
      .unwrap()
      .then((payload) => {
        setSuccessMessage(payload.message);
      })
      .catch((err) => {
        if (err.data) {
          setErrorMessage(err.data.message);
        } else {
          setErrorMessage(err.error);
        }
      });
  };
  const onClickSubmit = (values: FormikValues) => {
    setSuccessMessage("");
    setErrorMessage("");
    const valuesArr = Object.values(values);
    const valuesToSend = valuesArr.reduce((result: FormikValues, current) => {
      return { ...result, ...current };
    }, {});
    // console.log("Submit Clicked", valuesToSend);

    submitProviderData(valuesToSend)
      .unwrap()
      .then((payload) => {
        setSuccessMessage(payload.message);
        if (
         ( payload.data?.Status ===
          process.env.REACT_APP_USER_ONBOARD_APPROVED_STATUS) || (payload.data?.Status === "Provider Accepted")
        ) {
          dispatch(
            setOnboardingStatus({
              status: payload.data.Status,
            })
          );
          navigate("/wizard");
        } else {
          navigate("/onboarding/submit-confirmation");
        }
      })
      .catch((err) => {
        if (err.data) {
          setErrorMessage(err.data.message);
        } else {
          setErrorMessage(err.error);
        }
      });

    // return returnedVal
  };

  return (
    <>
      <Layout
        logo={diligent_logo}
        title={sideBarData[currentIndex].title}
        subTitle={sideBarData[currentIndex].subTitle}
        backgroundImage={sideBarData[currentIndex].logo}
        children={
          <Box
            sx={{
              height: "100%",
              maxWidth: "750px",
              minWidth: "80%",
              margin: "auto",
            }}
          >
            {isLoading ? (
              <AppLoader open={true} />
            ) : (
              <HorizontalStepper
                currentIndex={currentIndex}
                steps={baseSteps}
                data={providerData.data ? providerData.data : {}}
                setCurrentIndex={(
                  form: FormikProps<FormikValues>,
                  index: number
                ) => onClickSetIndex(form, index)}
                onClickNext={(form: FormikProps<FormikValues>, name: string) =>
                  onClickNext(form, name)
                }
                onClickSave={(form: FormikProps<FormikValues>, name: string) =>
                  onClickSave(form, name)
                }
                onClickSubmit={(values) => onClickSubmit(values)}
                formErrors={formErrors}
                basePath={"/onboarding/"}
              />
            )}
            {errorMessage && (
              <AppSnackBar
                open={true}
                time={2000}
                message={errorMessage}
                type={"error"}
              />
            )}
            {successMessage && (
              <AppSnackBar
                open={true}
                time={2000}
                message={successMessage}
                type={"success"}
              />
            )}
          </Box>
        }
      />
    </>
  );
}
