import React, { useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { Formik, FormikProps, FormikValues } from "formik";
import Navigation from "./Navigation";
import { generateInitialValues, getStepSchema } from "./stepperUtils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Steps } from "../../types/onboarding";
import { useNavigate } from "react-router-dom";
import AppSnackBar from "../reuseable/appSnackBar";

export interface HorizontalStepperProps {
  steps: Steps[];
  data: FormikValues;
  currentIndex: number;
  setCurrentIndex:  (form: FormikProps<FormikValues>, index: number) => void;
  onClickNext: (form: FormikProps<FormikValues>, name: string) => void;
  onClickSave: (form: FormikProps<FormikValues>, name: string) => void;
  onClickSubmit: (values: FormikValues) => void;
  formErrors: any;
  basePath: string;
}

export default function HorizontalStepper({
  steps,
  data = {},
  currentIndex,
  setCurrentIndex,
  onClickNext,
  onClickSave,
  onClickSubmit,
  formErrors,
  basePath,
}: HorizontalStepperProps) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // const [openSnackBar, setOpenSnackBar] = useState(false);
  const [initialValues] = useState(generateInitialValues(steps, data));

  const renderCurrentStep = (form: FormikProps<FormikValues>) => {
    const step = steps[currentIndex];
    // opportunity to extend commonProps here with other relevant information
    const commonProps = {
      name: step.name,
      form,
      errors: form.errors[step.name],
    };

    const StepComponent = step.component;

    return <StepComponent {...commonProps} />;
  };

  const handleClick = (form: FormikProps<FormikValues>, index: number) => {
    const step = steps[index];
    setCurrentIndex(form,index);
    navigate(`${basePath}${step.name}`);
  };

  const renderStepper = (form: FormikProps<FormikValues>) => {
    const currStep = steps[currentIndex];
    return (
      <Stack sx={{ width: "100%", marginBottom: "40px" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={currentIndex}
          orientation='horizontal'
          // connector={<ColorConnector />}
          sx={{
            "& .MuiStep-root": { textAlign: "center" },
            "& .MuiStepLabel-root": {
              minWidth: "120px",
            },
            "& .MuiStepConnector-alternativeLabel": {
              top: "20px",
              left: "calc(-50% + 13px)",
              right: "calc(50% + 13px)",
            },
            "& .Mui-completed": {
              "& .MuiStepConnector-line": {
                borderColor: "#4362EA",
              },
            },
            "& .Mui-active": {
              "& .MuiStepConnector-line": {
                borderColor: "#4362EA",
              },
            },
            "& .MuiStepConnector-line": {
              borderTopWidth: "5px",
            },
          }}
        >
          {steps.map((step: any, index: number) => (
            <Step
              key={step.name}
              active={currStep.name === step.name}
              // disabled={index > currentIndex}
            >
              <Button
                // disabled={index > currentIndex}
                sx={{
                  cursor: "pointer",
                  minWidth: "40px",
                  height: "40px",
                  color:
                  index <= currentIndex ? (index !== currentIndex &&
                    formErrors[step.name]?.errors &&
                    Object.keys(formErrors[step.name].errors).length
                      ? "red"
                      : "#4362EA") : '#bdbdbd',
                }}
                onClick={() => handleClick(form,index)}
              >
                <StepLabel
                  icon={renderCurrentIcon(
                    index !== currentIndex ? (formErrors[step.name]?.errors &&
                      !Object.keys(formErrors[step.name].errors).length
                      ? CheckCircleIcon
                      : step.icon) : step.icon
                  )}
                  sx={{
                    position: "absolute",
                    top: "6px",
                    lineHeight: 1,
                    fontSize: "0.6",
                    "& .MuiSvgIcon-root": {
                      height: "32px",
                      width: "32px",
                    },
                    "& .MuiStepLabel-label": {
                      marginTop: "5px",
                    },
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      color: "#111",
                      lineHeight: "14px",
                    }}
                  >
                    {step.title}
                  </Typography>
                </StepLabel>
              </Button>
            </Step>
          ))}
        </Stepper>
      </Stack>
    );
  };

  const renderCurrentIcon = (icon: any) => {
    const StepIconComponent = icon;
    return <StepIconComponent />;
  };

  useEffect(() => {
    setCustomErrorMessage("")
  }, [currentIndex])
  
  const splitStepName = (name: string) => {
    const s1 = name.slice(4);
    const s2 = name.slice(0,4);
    return `${s2.charAt(0).toUpperCase() + s2.slice(1)} ${s1}`
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={getStepSchema(currentIndex, steps)}
          onSubmit={(values, {setSubmitting}) => {
            setSuccessMessage("");
            setErrorMessage("");
            const errArr = Object.values(formErrors);
            // @ts-ignore:next-line
            const error = errArr.find(ele=> ele?.errors && Object.keys(ele.errors).length);
            if (!error) {
              onClickSubmit(values)
            } else {
              // @ts-ignore:next-line
              setCustomErrorMessage(`There is an error at ${splitStepName(error.name)}. Please go Back to correct before submitting here.`)
              setErrorMessage("There is an error in the form.")
              setSubmitting(false)
            }
            setTimeout(() => {
              setSubmitting(false)
            }, 5000);
          }}
          validateOnMount
          validateOnChange
        >
          {(form) => {
            return (
              <>
                <Box>{renderStepper(form)}</Box>
                <Box>{renderCurrentStep(form)}</Box>
                <Typography variant="body1" sx={{marginTop: '10px', textAlign: 'center', color: 'red'}}>
                  {customErrorMessage}
                </Typography>
                <Navigation
                  maxSteps={steps.length}
                  currentIndex={currentIndex}
                  onClickNext={onClickNext}
                  onClickSave={onClickSave}
                  setCurrentIndex={setCurrentIndex}
                  resetFormOnSubmit={false}
                  form={form}
                  steps={steps}
                />
                {errorMessage ? (
                  <AppSnackBar open={Boolean(errorMessage)} time={5000} message={errorMessage} type={"error"} />
                ) : ''}
                {successMessage && (
                  <AppSnackBar
                    open={true}
                    message={successMessage}
                    type={"success"}
                  />
                )}
              </>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}
