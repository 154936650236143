import React from 'react'
import { FormControl, InputLabel, TextField } from '@mui/material'
import {  FormikProps, FormikValues, useFormikContext } from 'formik'
import { materialInputStyles } from './InputMaterial'

export interface InputMaterialProps {
  id: string;
  label: string;
  placeholder?: string;
  value: string;
  minRows: number;
  maxRows: number;
  formName: string;
  error: string | undefined;
  touched: any;
}
export interface FieldType {
  [key: string]: string
}

export const materialTextBoxStyles = {
    ...materialInputStyles,
    '.MuiInputBase-root': {
        background: 'rgba(252, 251, 254, 0.8)',
        border: '1px solid #C0C5D1',
        borderRadius: '6px',
        height: '100%'
      },
}

export default function TextBoxMaterial ({
  id,
  label,
  placeholder,
  value,
  minRows,
  maxRows,
  formName,
  error,
  touched,
}: InputMaterialProps) {
  const form = useFormikContext<FormikProps<FormikValues>>()

  return (
    <>
      <InputLabel
        sx={{ fontWeight: 700, color: touched && Boolean(error) ? 'red' : '' }}
      >
        {label}
      </InputLabel>
      <FormControl variant='standard' fullWidth>
        <TextField
          id={id}
          name={`${formName}.${id}`}
          multiline
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          value={value}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={touched && error ? error : ""}
          error={touched && Boolean(error)}
          margin='dense'
          fullWidth
          sx={materialTextBoxStyles}
        />
      </FormControl>
    </>
  )
}
