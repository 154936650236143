import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import { useFormikContext } from "formik";
import { Autocomplete, Chip, FormHelperText, TextField } from "@mui/material";

export type ItemType = {
  title: string;
  value: string;
  id?: string;
  IsMandatory?: boolean
};
export interface SelectMaterialProps {
  id: string;
  label: string;
  placeholder?: string;
  type?: "single" | "multiple";
  formName: string;
  options: Array<ItemType>;
  selected: Array<string>;
  error: string | undefined;
  touched: any;
  disabled?: boolean;
  dependantValue?:Array<string>
}
export interface FieldType {
  [key: string]: string[];
}

export default function SelectField({
  id,
  label,
  placeholder,
  type = "single",
  formName,
  options,
  selected,
  dependantValue,
  error,
  touched,
  disabled
}: SelectMaterialProps) {
  const form = useFormikContext<FieldType>();
  const [picked, setPicked] = React.useState<ItemType[]>([]);
  const name = `${formName}.${id}`;
  const handleChange = (e: React.SyntheticEvent, value: ItemType[]): void => {
    const noneValue = value.filter(val=>val.title === "None or N/A");
    if (!value.length) {
      setPicked([]);
      form.setFieldValue(name, []);
      return;
    }
    if (noneValue.length) {
      setPicked(noneValue);
      form.setFieldValue(name, ["None or N/A"]);
    } else {
      const newValue = value.reduce(
        (result: Array<string>, current: ItemType) => {
          return [...result, current.title];
        },
        []
      );
      setPicked(value);
      form.setFieldValue(name, newValue);
    }
  };

  React.useEffect(() => {
    const value = options.filter((ele) => {
      
      if (ele?.IsMandatory) {
        return true
      }
      const temp = selected ? selected : dependantValue || []
     return temp.includes(ele.title)
    });
    setPicked(value);
  }, [options, selected,dependantValue]);

  return (
    <div>
      <InputLabel
        id={`select${id}`}
        sx={{
          fontWeight: 700,
          color: touched && Boolean(error) ? "red" : "",
        }}
      >
        {label}
      </InputLabel>
      <Autocomplete
        multiple
        fullWidth
        limitTags={5}
        onChange={handleChange}
        id={`${id}-select`}
        options={options}
        value={picked}
        // open
        disabled={disabled}
        disableClearable
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => value.title === option.title}
        renderOption={(props, option) => <li key={option.id} {...props}>{option.title}</li>}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.title}
              {...getTagProps({ index })}
              disabled={option?.IsMandatory ? true : false}
            />
          ))
        }
        sx={{
          backgroundColor: disabled ? "#e8e9ed" : "#FFF",
          borderRadius: "6px",
          border: "none",
          marginTop: "8px",
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          "& .MuiFormControl-root": {
            borderRadius: "6px",
            border: touched && error ? "1px solid red" : "1px solid #C0C5D1",
            minHeight: "51px"
          },
          "& .MuiFormControl-root:hover": {
            border: disabled ? "1px solid #C0C5D1" : "1px solid #1565D8",
            boxShadow: disabled ? "none" : "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
          },
          "& .MuiOutlinedInput-root":{
            padding: "6px"
          },
          "& .Mui-disabled": {
            opacity: "0.6"
          }
        }}
        renderInput={(params) => (
          <TextField variant='outlined' {...params} placeholder={placeholder} />
        )}
      />
       {touched && error? (
         <FormHelperText
            sx={{ color: "red" }}
         >
            {error}
         </FormHelperText>
         ) : null}
    </div>
  );
}
