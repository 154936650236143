import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOnboardingErrors,
  setOnboardingErrors,
} from "../../features/onboardingPortal/onboardingSlice";
import InputMaterial from "../reuseable/formFields/InputMaterial";
import TextBoxMaterial from "../reuseable/formFields/TextBoxMaterial";
import StepHeader from "./StepHeader";
import { FormProps } from "../../types/onboarding";
import { useGetCountriesQuery } from "../../features/onboardingPortal/onboardingPortalApiSlice";
import SingleSelect from "../reuseable/formFields/SingleSelect";

function sortCountries(arr:any) {
  return arr.slice().sort((a: { Country: string; }, b: { Country: string; }) => {
    let fa = a.Country.toLowerCase(),
        fb = b.Country.toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
})
}

export default function Step1({ form, name, errors }: FormProps) {
  const dispatch = useDispatch();
  const formErrors = useSelector(selectOnboardingErrors);
  const { data:countries = [] } = useGetCountriesQuery({});
  
  useEffect(() => {
    let dataToStore = {
      name: name,
      isValid: form.isValid,
      status: true,
      errors: form.errors[name] ? form.errors[name] : {},
      touched: form.touched[name],
    };
    dispatch(setOnboardingErrors(dataToStore));
  }, [dispatch, form.errors, form.isValid, form.status, form.touched, name]);

  return (
    <Grid container>
      <Grid item p={"10px 25px"} xs={12}>
        <StepHeader
          title={"General Company Information"}
          subTitle={
            "Please provide your legal company name, primary/HQ address, phone and general description of your company and the services you offer."
          }
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={6}>
        <InputMaterial
          id={"ProviderName"}
          placeholder={"Company Name"}
          label={"Full Legal Company Name*"}
          name={`${name}.ProviderName`}
          value={form.values.step1.ProviderName}
          error={formErrors.step1?.errors?.ProviderName}
          touched={formErrors.step1?.touched?.ProviderName}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={6}>
         <InputMaterial
          id={"Website"}
          placeholder={"www.companyname.com"}
          label={"Company url*"}
          name={`${name}.Website`}
          disabled
          value={form.values.step1.Website}
          error={formErrors.step1?.errors?.Website}
          touched={formErrors.step1?.touched?.Website}
        />
      </Grid>
      {/* <Grid item p={"10px 25px"} xs={12} md={6}>
        <InputMaterial
          id={"ProviderFileName"}
          placeholder={"Short Company Name"}
          label={"Short Company Name*"}
          name={`${name}.ProviderFileName`}
          value={form.values.step1.ProviderFileName}
          error={formErrors.step1?.errors?.ProviderFileName}
          touched={formErrors.step1?.touched?.ProviderFileName}
        />
      </Grid> */}
      <Grid item p={"10px 25px"} xs={12} md={12}>
      <InputMaterial
          id={"Address"}
          placeholder={"North Street"}
          label={"Address 1*"}
          name={`${name}.Address`}
          value={form.values.step1.Address}
          error={formErrors.step1?.errors?.Address}
          touched={formErrors.step1?.touched?.Address}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={12}>
      <InputMaterial
          id={"Address2"}
          placeholder={"Address 2"}
          label={"Address 2"}
          name={`${name}.Address2`}
          value={form.values.step1.Address2}
          error={formErrors.step1?.errors?.Address2}
          touched={formErrors.step1?.touched?.Address2}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={6}>
          <SingleSelect
          id={"Country"}
          formName={name}
          type={"single"}
          label={"Country*"}
          placeholder={"Select Country"}
          options={
            countries.data
              ? sortCountries(countries.data).reduce(
                  (result: Array<string>, current: { Country: string }) => {
                    return current.Country
                      ? [...result, current.Country]
                      : result;
                  },
                  []
                )
              : []
          }
          selected={[form.values[name].Country]}
          error={formErrors.step1?.errors?.Country}
          touched={formErrors.step1?.touched?.Country}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={6}>
        <InputMaterial
          id={"State"}
          placeholder={"State"}
          label={"State/Province"}
          name={`${name}.State`}
          value={form.values.step1.State}
          error={formErrors.step1?.errors?.State}
          touched={formErrors.step1?.touched?.State}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={6} >
        <InputMaterial
          id={"City"}
          placeholder={"City"}
          label={"City*"}
          name={`${name}.City`}
          value={form.values.step1.City}
          error={formErrors.step1?.errors?.City}
          touched={formErrors.step1?.touched?.City}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={6} >
        <InputMaterial
          id={"ZipCode"}
          placeholder={"Postal Code"}
          label={"Postal Code*"}
          name={`${name}.ZipCode`}
          // type="number"
          value={form.values.step1.ZipCode}
          error={formErrors.step1?.errors?.ZipCode}
          touched={formErrors.step1?.touched?.ZipCode}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={12}>
      <InputMaterial
          id={"PhoneNumber"}
          placeholder={"Phone"}
          label={"Phone*"}
          name={`${name}.PhoneNumber`}
          // type="number"
          value={form.values.step1.PhoneNumber}
          error={formErrors.step1?.errors?.PhoneNumber}
          touched={formErrors.step1?.touched?.PhoneNumber}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={12}>
        <TextBoxMaterial
          id={"Description"}
          placeholder={"Description"}
          label={"Description"}
          minRows={2}
          maxRows={3}
          formName={name}
          value={form.values.step1.Description}
          error={formErrors.step1?.errors?.Description}
          touched={formErrors.step1?.touched?.Description}
        />
      </Grid>
    </Grid>
  );
}
