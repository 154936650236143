import React, { useEffect, useState } from "react";
import Layout from "../../components/reuseable/layout";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.svg";
import { Box, Typography } from "@mui/material";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { wizardSchema } from "./formSchema";
import { headerStyle, initialDefaultValues } from "./constant";
import MoreInfo from "../../components/wizardPages/MoreInfo";
import LocationForm from "../../components/wizardPages/LocationForm";
import LocationConfirmation from "../../components/wizardPages/LocationConfirmation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getState } from "../auth/authSlice";
import Template from "../../components/wizardPages/Template";
import {
  useGetProviderWizardDataQuery,
  useSaveWizardMutation,
  useSubmitProviderWizardFormMutation,
} from "./OnboardingWizardApiSlice";
import AppSnackBar from "../../components/reuseable/appSnackBar";
import AppLoader from "../../components/reuseable/appLoader";
import {
  selectWizardIsPlatformUser,
  setOnboardingWizardStatus,
} from "./OnboardingWizardSlice";

export type LocationType = {
  LocationName?: string;
  Address: string;
  Address2: string;
  IsActive?: boolean;
  IsPrimary?: boolean;
  City: string;
  ShortCityName?: string;
  State: string;
  Country: string;
  ZipCode: string;
  GeographicRegionsServed: Array<string>;
  Capabilities: Array<string>;
  NumberOfMultiSubsidiaries?: number | string | undefined;
  SameCapabilitiesOffered?: boolean | undefined;
  ProviderName?: string | undefined;
  QMS: string;
  QMSType?: string | undefined;
};

interface OnboardingWizardPageProps {
  name: string;
}

const layoutLookup = [
  {
    name: "more-info",
    title: "Welcome to the Diligent Qualification Platform Setup Wizard",
    subTitle: [],
    image: signup_sidebar_img,
    pageTitle:
      "Tell us more about your company and we will set things up for you",
    pageSubTitle: "",
  },
  {
    name: "locations",
    title: "Welcome to the Diligent Qualification Platform Setup Wizard",
    subTitle: [],
    image: signup_sidebar_img,
    pageTitle: "Please specify the address for each location",
    pageSubTitle: "",
  },
  {
    name: "locations-confirmation",
    title: "Welcome to the Diligent Qualification Platform Setup Wizard",
    subTitle: [],
    image: signup_sidebar_img,
    pageTitle: "Please confirm all the locations",
    pageSubTitle: "",
  },
  {
    name: "submit",
    title: "Welcome to the Diligent Qualification Platform Setup Wizard",
    subTitle: [],
    image: signup_sidebar_img,
    pageTitle: "",
    pageSubTitle: [""],
  },
];

export default function OnboardingWizardPage({
  name = "more-info",
}: OnboardingWizardPageProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currFormName, setCurrFormName] = useState(name);
  const { status, user, token } = useSelector(getState);
  const { isPlatformUser } = useSelector(selectWizardIsPlatformUser);
  const {
    data: userData,
    isLoading,
    error,
  } = useGetProviderWizardDataQuery({});
  const [saveWizardData] = useSaveWizardMutation();
  const [submitWizardData] = useSubmitProviderWizardFormMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [initialValues, setInitialValues] = useState(initialDefaultValues);
  const [loader, setLoader] = useState(false);
  const [position, setPosition] = useState(0);

  const layoutConfig = () => {
    const found = layoutLookup.find((ele) => ele.name === name);
    return found ? found : layoutLookup[0];
  };

  useEffect(() => {
    if (error && "data" in error) {
      setErrorMessage(error.data.message);
    } else {
      if (userData && userData.data) {
        if (userData.data?.IsPlatformReady && userData.data.IsPlatformReady) {
          dispatch(setOnboardingWizardStatus(true));
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("status");
          window.location.replace(
            process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token
          );
        }
        setInitialValues({ ...initialDefaultValues, ...userData.data });
      }
    }
  }, [dispatch, error, navigate, token, userData]);

  useEffect(() => {
    if (isPlatformUser) {
      navigate("/wizard/submit");
    }
    if (user !== "Wizard") {
      navigate("/onboarding");
    }
    if (
      ![
        process.env.REACT_APP_USER_DEFAULT_WIZARD_STATUS,
        process.env.REACT_APP_USER_ONBOARD_APPROVED_STATUS,
      ].includes(status)
    ) {
      navigate("/onboarding");
    }
  }, [isPlatformUser, navigate, status, user]);

  useEffect(() => {
    setCurrFormName(name);
  }, [currFormName, name]);

  const onClickSave = (form: FormikProps<FormikValues>) => {
    // console.log("Save Clicked", form);
    setSuccessMessage("");
    setErrorMessage("");
    let dataToSend = {
      ...form.values,
      Locations: form.values.Locations.filter(
        (ele: LocationType) =>
          ele.City !== "" && ele.Address !== "" && ele.Country !== ""
      ),
    };

    saveWizardData(dataToSend)
      .unwrap()
      .then((payload) => {
        setSuccessMessage(payload.message);
      })
      .catch((err) => {
        if (err.data) {
          setErrorMessage(err.data.message);
        } else {
          setErrorMessage(err.error);
        }
      });
  };

  const onClickSubmit = (form: FormikProps<FormikValues>) => {
    // console.log("Submit Clicked", form);
    setSuccessMessage("");
    setErrorMessage("");
    setLoader(true);
    let dataToSend = {
      ...form.values,
      Locations: form.values.Locations.filter(
        (ele: LocationType) =>
          ele.City !== "" &&
          ele.Address !== "" &&
          ele.Country !== ""
      ),
    };

    submitWizardData(dataToSend)
      .unwrap()
      .then((payload) => {
        setSuccessMessage(payload.message);
        setTimeout(() => {
          setLoader(false);
          // console.log("Submit values: ", dataToSend);
          dispatch(setOnboardingWizardStatus(true));
          navigate("/wizard/submit");
        }, 1000);
      })
      .catch((err) => {
        setLoader(false);
        if (err.data) {
          setErrorMessage(err.data.message);
        } else {
          setErrorMessage(err.error);
        }
      });
  };

  const pageNextHandler = (to: string, position?: number) => {
    navigate(to);
    const pos = position ? position : 0;
    const paths = to.split("/");
    setCurrFormName(paths[paths.length ? paths.length - 1 : 0]);
    setPosition(pos);
  };

  return (
    <Layout
      logo={diligent_logo}
      title={layoutConfig().title}
      subTitle={layoutConfig().subTitle}
      backgroundImage={layoutConfig().image}
      children={
        isLoading ? (
          <AppLoader open={true} />
        ) : (
          <Box
            sx={{
              height: "100%",
              margin: "3rem auto",
              maxWidth: "805px",
              width: "100%",
            }}
          >
            <Box
              sx={{ margin: "auto", maxWidth: "700px", textAlign: "center" }}
            >
              <Typography
                variant='h3'
                sx={{ ...headerStyle, marginTop: "3rem" }}
              >
                {layoutConfig().pageTitle}
              </Typography>
              <Typography variant='h4' sx={{ marginTop: "3rem" }}>
                {layoutConfig().pageSubTitle}
              </Typography>
            </Box>
            <Box
              component={"div"}
              sx={{
                margin: "auto",
              }}
            >
              <Formik
                initialValues={{ ...initialValues }}
                validationSchema={wizardSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  setLoader(true);
                  setSuccessMessage("");
                  setErrorMessage("");
                  // console.log("Submit: ", values);
                  submitWizardData(values)
                    .unwrap()
                    .then((payload) => {
                      setSuccessMessage(payload.message);
                      setTimeout(() => {
                        setLoader(false);
                        // console.log("Submit values: ", values);
                        dispatch(setOnboardingWizardStatus(true));
                        navigate("/wizard/submit");
                      }, 1000);
                    })
                    .catch((err) => {
                      setLoader(false);
                      if (err.data) {
                        setErrorMessage(err.data.message);
                      } else {
                        setErrorMessage(err.error);
                      }
                    });
                }}
                enableReinitialize
                validateOnMount
                validateOnChange
              >
                {(form) => {
                  return (
                    <Form onSubmit={form.handleSubmit}>
                      {currFormName === "more-info" && (
                        <MoreInfo
                          name={"more-info"}
                          onClickNext={(from) => pageNextHandler(from)}
                        />
                      )}
                      {currFormName === "locations" && userData && (
                        <LocationForm
                          name={"locations"}
                          onClickSave={(form) => onClickSave(form)}
                          onClickNext={(from) => pageNextHandler(from)}
                          onClickSubmit={(form) => onClickSubmit(form)}
                          extraCapabilityField={
                            !form.values.DoLocationsOfferSameCapabilities
                          }
                          extraQmsField={
                            form.values.QualityManagementSystemType === "Hybrid"
                          }
                          hasSubsidiaries={form.values.HasSubsidiaries}
                          locations={
                            userData.data ? userData.data.Locations : []
                          }
                          position={position ? position : 0}
                        />
                      )}
                      {currFormName === "locations-confirmation" &&
                        form.isValid && (
                          <LocationConfirmation
                            currLocation={form.values.Locations.length}
                            setCurrLocation={(from, pos) =>
                              pageNextHandler(from, pos)
                            }
                            Locations={form.values.Locations}
                          />
                        )}
                      {currFormName === "submit" && <Template />}
                    </Form>
                  );
                }}
              </Formik>
              {(isLoading || loader) && <AppLoader open={true} />}
              {errorMessage && (
                <AppSnackBar
                  open={true}
                  time={2000}
                  message={errorMessage}
                  type={"error"}
                />
              )}
              {successMessage && (
                <AppSnackBar
                  open={true}
                  time={2000}
                  message={successMessage}
                  type={"success"}
                />
              )}
            </Box>
          </Box>
        )
      }
    />
  );
}
