import React, { useEffect } from "react";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import { Box, Button, CircularProgress } from "@mui/material";
import { Steps } from "../../types/onboarding";

type NavigationProps = {
  maxSteps: number;
  currentIndex: number;
  onClickNext: (form: FormikProps<FormikValues>, name: string) => void;
  onClickSave: (form: FormikProps<FormikValues>, name: string) => void;
  setCurrentIndex:  (form: FormikProps<FormikValues>, index: number) => void;
  resetFormOnSubmit: boolean;
  form: FormikProps<FormikValues>;
  steps: Steps[];
};

const Navigation = ({
  maxSteps,
  currentIndex,
  onClickNext,
  onClickSave,
  setCurrentIndex,
  form,
  resetFormOnSubmit = false,
  steps,
}: NavigationProps) => {
  const isLastStep = currentIndex === maxSteps - 1;

  // Grab what we need from formik without prop-drilling
  const { validateForm, handleSubmit, isSubmitting } = useFormikContext();

  // Will run form.validateForm() when the currentIndex prop is changed
  useEffect(() => {
    validateForm();
  }, [currentIndex, validateForm]);

  const buttonStyle = {
    minWidth: "150px",
    height: "44px",
    margin: "20px 15px 30px 15px",
    textTransform: "none",
  };

  return (
    <Box sx={{ justifyContent: "center", display: "flex" }}>
      {currentIndex !== 0 && (
        <Button
          variant='contained'
          onClick={() => setCurrentIndex(form, currentIndex-1)}
          sx={{ ...buttonStyle }}
        >
          Back
        </Button>
      )}
      {!isLastStep && (
        <Button
          variant='outlined'
          onClick={() => onClickSave(form, steps[currentIndex].name)}
          sx={{ ...buttonStyle }}
        >
          Save
        </Button>
      )}

      {currentIndex === maxSteps - 1 ? (
        <Button
          variant='contained'
          disabled={isSubmitting}
          onClick={() =>  handleSubmit() }
          sx={{ ...buttonStyle }}
        >
          {isSubmitting ? (
            <CircularProgress size={14} sx={{ color: "#FFF" }} />
          ) : (
            "Submit"
          )}
        </Button>
      ) : (
        <Button
          variant='contained'
          onClick={() => onClickNext(form, steps[currentIndex].name)}
          sx={{ ...buttonStyle }}
        >
          {"Next"}
        </Button>
      )}
    </Box>
  );
};

export default Navigation;
