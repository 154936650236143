import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { apiSlice } from "../../app/api/apiSlice";
import { getState } from "../../features/auth/authSlice";
import Layout from "../../components/reuseable/layout";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.svg";
import AppLoader from "../../components/reuseable/appLoader";
import { useGetProviderWizardDataQuery } from "./OnboardingWizardApiSlice";
import AppSnackBar from "../../components/reuseable/appSnackBar";
import { selectWizardIsPlatformUser } from "./OnboardingWizardSlice";

export default function SubmitConfirmation() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, user, token } = useSelector(getState);
  const { isPlatformUser } = useSelector(selectWizardIsPlatformUser);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    data: userData,
    isLoading,
    error,
  } = useGetProviderWizardDataQuery({});

  useEffect(() => {
    if (error && "data" in error) {
      setErrorMessage(error.data.message);
    } else {
      if (userData && userData.data) {
        if (userData.data?.IsPlatformReady && userData.data.IsPlatformReady) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("status");
          window.location.replace(
            process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token
          );
        }
      }
    }
  }, [error, navigate, token, userData]);

  useEffect(() => {
    if (isPlatformUser) {
      navigate("/wizard/submit");
    }
    if (user !== "Wizard") {
      navigate("/onboarding");
    }
    if (status === process.env.REACT_APP_USER_DEFAULT_WIZARD_STATUS) {
      navigate("/wizard/more-info");
    }
  }, [isPlatformUser, navigate, status, user]);

  const handleClick = () => {
    // TBD handle to change status from REACT_APP_USER_ONBOARD_APPROVED_STATUS to REACT_APP_USER_DEFAULT_WIZARD_STATUS
    navigate("/wizard/more-info");
  };

  return (
    <Box>
      <Layout
        logo={diligent_logo}
        title={"Welcome to the Diligent Qualification Platform Setup Wizard"}
        subTitle={[]}
        backgroundImage={signup_sidebar_img}
        children={
          isLoading ? (
            <AppLoader open={true} />
          ) : (
            <Box
              component={"div"}
              sx={{
                maxWidth: "503px",
                minHeight: "30vh",
                display: "flex",
                margin: "auto",
              }}
            >
              <Box
                component={"div"}
                sx={{ textAlign: "center", margin: "auto", color: "#000000" }}
              >
                <Typography
                  variant='h4'
                  sx={{ marginBottom: "2em", fontWeight: 600 }}
                >
                  Congratulations!
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    marginBottom: "1em",
                    fontWeight: 500,
                    lineHeight: "1em",
                  }}
                >
                  You are now in the DQP Provider Community
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    marginBottom: "3em",
                    fontWeight: 500,
                    lineHeight: "1em",
                  }}
                >
                  Take the next steps now to promote your capabilities to
                  Sponsors within the Diligent Qualification Platform.
                </Typography>
                <Button
                  variant='contained'
                  onClick={handleClick}
                  sx={{
                    textTransform: "none",
                    borderRadius: "6px",
                    height: "3.2rem",
                    minWidth: "170px",
                    fontSize: "1rem",
                    marginTop: "20px",
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )
        }
      />
      {errorMessage && (
        <AppSnackBar
          open={true}
          time={2000}
          message={errorMessage}
          type={"error"}
        />
      )}
    </Box>
  );
}
