import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { buttonStyle } from "../../features/onbordingWizard/constant";
import { useSelector } from "react-redux";
import { getState } from "../../features/auth/authSlice";

export default function Template() {
  const { token } = useSelector(getState);

  const handleConfirm = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("status");
    window.location.replace(
      process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token
    );
  };

  return (
    <Box sx={{ minWidth: "500px", marginTop: "30vh", textAlign: "center" }}>
      <Box component={"div"} sx={{ textAlign: "center", margin: "10px" }}>
        <Typography variant='h5'>
        Thank you for providing your location and capabilities information.
        </Typography>
        <Typography variant='body1'>
        You may now access your provider dashboard within the Diligent Qualification Platform by navigating to <a href={process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token}>platform.diligentpharma.com</a> or by clicking Get Started below.  A Diligent Pharma representative will contact you shortly to discuss next steps.  You may also access the Quick Reference Guides by clicking on Need Help.
        </Typography>
        <Typography variant='h5'>
        Welcome to the Diligent Qualification Platform!
        </Typography>

        <Button
          variant='contained'
          disabled={false}
          onClick={handleConfirm}
          sx={{ ...buttonStyle }}
        >
          {"Get Started"}
        </Button>
      </Box>
    </Box>
  );
}
