import React, { useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  ButtonField,
  InputMaterial,
  PasswordField,
} from "../../components/reuseable/formFields";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.png";
// import SideBar from '../../components/reuseable/sideBar'
import { Formik, Form } from "formik";
import { signInValidationSchema } from "./authSchema";
import Layout from "../../components/reuseable/layout/Layout";
import { authCardStyles, authHeaderStyle } from "./constant";
import { useLoginMutation } from "./authApiSlice";
import { setCredentials } from "./authSlice";
import { useDispatch } from "react-redux";
import AppSnackBar from "../../components/reuseable/appSnackBar";
import AppLoader from "../../components/reuseable/appLoader";

export default function SignInPage() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const initialValues = {
    email: "",
    password: "",
  };

  const [login, { data, error, isLoading }] = useLoginMutation();

  useEffect(() => {
    if (error && "data" in error) {
      setErrorMessage(error.data.message);
    } else {
      setSuccessMessage(data && data.message);
      const accessToken = data?.data?.access_token;
      const status = data?.data?.status;
      const userState = (status === process.env.REACT_APP_USER_ONBOARD_APPROVED_STATUS) ? "Wizard" : "Portal";
      dispatch(
        setCredentials({
          user: userState,
          accessToken: accessToken ? accessToken : "",
          status: status ? status : process.env.REACT_APP_USER_DEFAULT_STATUS,
        })
      );
    }
  }, [data, dispatch, error, login]);

  return (
    <Layout
      logo={diligent_logo}
      title={
        "Submit your company information for entry into the DQP Provider Community through the Provider Onboarding Portal."
      }
      subTitle={[
        "Upon our approval, your company will be available in our Provider Community for Sponsors on the DQP Platform.",
      ]}
      backgroundImage={signup_sidebar_img}
      children={
        <Card sx={{ ...authCardStyles, padding: "10%" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={signInValidationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setErrorMessage("");
              await login({
                Email: values.email,
                PasswordHash: values.password,
              });

              if (data && data.message) {
                resetForm();
              }

              setSubmitting(false);
            }}
          >
            {(form) => {
              return (
                <Form>
                  <Typography variant='h3' sx={authHeaderStyle}>
                    Please sign in!
                  </Typography>
                  <Box component={"div"} sx={{ marginBottom: "20px" }}>
                    <InputMaterial
                      id={"email"}
                      label={"Email*"}
                      placeholder={"Please enter email"}
                      name={"email"}
                      value={form.values.email}
                      error={form.errors.email as string}
                      touched={form.touched.email}
                    />
                  </Box>
                  <Box component={"div"} sx={{ marginBottom: "20px" }}>
                    <PasswordField
                      label={"Password*"}
                      id={"password"}
                      placeholder={"Please enter password"}
                    />
                  </Box>
                  <Box
                    component={"div"}
                    sx={{
                      height: "2rem",
                      textAlign: "end",
                      marginBottom: "20px",
                    }}
                  >
                    <Box component={"span"}>
                      <Link to={"/reset-password"}>Forgot Password?</Link>
                    </Box>
                  </Box>
                  <Box component={"div"} sx={{ marginBottom: "20px" }}>
                    <ButtonField
                      text={"Sign In"}
                      type={"submit"}
                      size='large'
                      disabled={form.isSubmitting}
                    />
                  </Box>
                  {errorMessage && (
                    <AppSnackBar
                      open={true}
                      message={errorMessage}
                      type={"error"}
                      time={5000}
                    />
                  )}
                  {successMessage && (
                    <AppSnackBar
                      open={true}
                      message={successMessage}
                      type={"success"}
                      time={5000}
                    />
                  )}
                  <Typography
                    variant='body1'
                    sx={{
                      textAlign: "center",
                      "& a": { textDecoration: "none" },
                    }}
                  >
                    Don't have an account? Click{" "}
                    <Link to={"/"}>
                      {" "}
                      <strong>here</strong>
                    </Link>{" "}
                    to Sign Up.
                  </Typography>
                </Form>
              );
            }}
          </Formik>
          <AppLoader open={isLoading} />
        </Card>
      }
    />
  );
}
