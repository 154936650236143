import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Link, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.svg";
import {
  ButtonField,
  PasswordField,
} from "../../components/reuseable/formFields";
import { signUpValidationSchema } from "./authSchema";
import InputMaterial from "../../components/reuseable/formFields/InputMaterial";
import Layout from "../../components/reuseable/layout/Layout";
import { authCardStyles, authHeaderStyle } from "./constant";
import { useRegisterMutation, useValidateInviteQuery } from "./authApiSlice";
import AppSnackBar from "../../components/reuseable/appSnackBar";
import AppLoader from "../../components/reuseable/appLoader";
import { buttonStyle } from "../onbordingWizard/constant";
import { Col, Input, Label, Row } from "reactstrap";
import DialogBox from "../../components/reuseable/formFields/dialog-box";
import CustomCheckbox from "../../components/reuseable/formFields/custom-checkbox";

type InitialValuesType = {
  firstname: string;
  lastname: string;
  email: string;
  companyUrl: string;
  password: string;
  confirmPassword: string;
  agreeToTerms: boolean;

};

type InviteDataType = {
  FirstName: string;
  LastName: string;
  Email: string;
  Website: string;
};

function generateInitialValues(data: InviteDataType) {
  const initialValues: InitialValuesType = {
    firstname: "",
    lastname: "",
    email: "",
    companyUrl: "",
    password: "",
    confirmPassword: "",
    agreeToTerms:false
  };

  if (data && data.Email) {
    return data.Email !== ""
      ? {
          ...initialValues,
          firstname: data.FirstName,
          lastname: data.LastName,
          email: data.Email,
          companyUrl: data.Website,
        }
      : initialValues;
  } else {
    return initialValues;
  }
}

export default function SignUp() {
  const [searchParams] = useSearchParams();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [popOpen, setPopOpen] = useState("");
  const token = searchParams.has("token") ? searchParams.get("token") : "";

  const [isActive, setIsActive] = useState<boolean>(false);

  const toggleIsActive = () => {
    setIsActive(true);
    
  };

    const [IsAgreement, setIsAgreement] = useState<boolean>(false);
  
    console.log(IsAgreement)

  const { 
    data: inviteTokenCheckData,
    isLoading: inviteLoading,
    error: inviteError,
  } = useValidateInviteQuery(
    { token: token as string },
    { skip: token ? false : true }
  );
  const [initialValues, setInitialValues] = useState<InitialValuesType>(
    generateInitialValues(inviteTokenCheckData?.data)
  );

  useEffect(() => {
    if (token && !inviteLoading) {
      if (inviteError && "data" in inviteError) {
        setErrorMessage(inviteError.data.message);
      }
    }
    setInitialValues(generateInitialValues(inviteTokenCheckData?.data));
  }, [inviteError, inviteLoading, inviteTokenCheckData, token]);

  const onCaptchaChange = (value: any) => {
    setErrorMessage("");
    setSuccessMessage("");
    setPopOpen("");
    setCaptchaToken(value);
  };

  const [register, { isLoading }] = useRegisterMutation();

  const TermsConditionBody = () => {
    return (
      <>
        <div className="text-center mb-3 mt-4">
          <Row className="justify-content-center">
            <Col>
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: "2rem",
                  marginTop: "1rem",
                  marginBottom: "1.5rem",
                  color: "#130D4F"
                }}
              >
                Terms & Conditions
              </h3>
            </Col>
          </Row>
          <div className="pl-5 pr-5">
            <div
              style={{
                height: "350px",
                overflow: "auto",
                textAlign: "left",
                fontSize: "0.6rem",
                color: "#000000",
                fontWeight: "300",
                border: "3px solid #EEEDF2",
                padding: "0.8rem"
              }}
            >
              <p
                style={{
                  fontSize: "0.6rem",
                  textDecoration: "underline",
                  marginBottom: "-0.1rem"
                }}
              >
                
                Effective Date: February 23, 2024 

              </p>
                The terms “Diligent,” “we,” “us” and “our” refer to Diligent 
                Pharma, Inc. and its affiliates (“Diligent Pharma”).
                Use of the Diligent® Qualification Platform (the “Platform”) 
                is subject to the following terms of use (“Terms of Use”). 
                By clicking agree, you, as the clinical service provider 
                (“you” or “Provider”) affirmatively accept these Terms of Use. 
                Please read these Terms of Use carefully and ensure that you understand them. 
                If you do not agree to the Terms of Use, you should not use the Platform. 
                DILIGENT PHARMA MAY UPDATE THESE TERMS OF USE AT ANY TIME, IN ITS SOLE DISCRETION. 
                EACH TIME YOU USE DILIGENT, THESE TERMS OF USE, AS THEY THEN READ, WILL GOVERN YOUR 
                USE OF THE PLATFORM. IN THE EVENT OF AN UPDATE, YOUR CONTINUED USE OF THE PLATFORM 
                FOLLOWING THE POSTING OF ANY CHANGES, SIGNALS YOUR UNDERSTANDING OF, AND AGREEMENT TO, SUCH CHANGES. 
              <br />
              <Label
                style={{
                  fontSize: "0.6rem",
                  textDecoration: "underline",
                  marginBottom: "-0.1rem"
                }}
              >
                Ability to Accept Terms of Use.
              </Label>{" "}
              The Platform is intended for use only by individuals who are at least 18 years old and by using the 
              Platform you represent that you are at least 18 years old. If you are consenting to these 
              Terms of Use on behalf of a legal entity, you represent and warrant that you have the authority to 
              consent to these Terms of Use on behalf of the entity. 
              <br />
              <Label
                style={{
                  fontSize: "0.6rem",
                  textDecoration: "underline",
                  marginBottom: "-0.1rem"
                }}
              >
                Scope.
              </Label>{" "}
              The Platform permits companies who have entered into an agreement with Diligent Pharma (“Authorized Consumers”) 
              seeking service from providers of clinical services like you, to search for and request their qualification 
              credentials, including qualification questionnaires, also called Requests for Information (or RFIs), and Vendor 
              Qualification Assessment audit reports (or VQAs). Diligent Pharma hereby grants to Provider during the Term 
              (defined below), a non-exclusive, non-transferable, limited right and license to use the Platform for its internal 
              business purposes (the “Platform License”). As a condition to the Platform License, Provider agrees to provide to 
              Diligent Pharma, data and information related to Provider’s business (including Confidential Information), as 
              requested by Diligent Pharma (collectively, the “Provider Data”). Upon the Provider’s prior written authorization, 
              Diligent Pharma will provide Authorized Consumers with access to the Provider Data via the Platform. No Provider 
              Data will be disclosed by Diligent Pharma to Authorized Consumers or any third party without the prior authorization 
              of Provider. Provider hereby grants to Diligent Pharma a royalty-free, non-exclusive, worldwide right and license 
              during the Term, to access, store and use the Provider Data as necessary to enforce its rights and perform its 
              obligations under these Terms of Use. Provider represents and warrants that the Provider Data will be accurate 
              and complete in all respects. Provider shall promptly correct any errors or inaccuracies related to the Provider 
              Data which Provider becomes aware. Notwithstanding anything to the contrary set forth in these Terms of Use, 
              Provider agrees that Diligent Pharma is authorized to use the Provider Data for Diligent Pharma’s analytical 
              and benchmarking purposes, and to improve its offerings and technology, provided that all such data is aggregated 
              and de-identified to third parties. Any derivative works of, or improvements to Diligent Pharma’s offerings, 
              including the Platform, arising from the use of Provider Data in accordance with this Section are the exclusive 
              property of Diligent Pharma. The term “Authorized Master User” refers to Provider’s employee or representative 
              who is authorized by Provider to use the Platform and has been supplied user credentials. The “Authorized Master 
              User” will have permissions and rights assigned within the Platform that allow the “Authorized Master User” 
              to create additional “Authorized Users” (or “Client Child Users” as defined within the Platform) for access 
              to the Platform. The term “Authorized User” refers to Provider’s employees who have been authorized by Provider 
              to use the Platform and have been supplied user credentials by the Provider. The “Authorized Master User” assumes 
              all responsibilities for managing the Provider’s user community. All acts and omissions of the Authorized User 
              community for Provider shall be deemed acts or omissions of Provider. Diligent Pharma does not assume any responsibility 
              or obligation for the accuracy, completeness, timeliness, or authenticity of information on the Platform. 
              Information obtained via the Platform should not be relied upon or used as the sole basis for making significant 
              decisions without consulting primary or more accurate, complete or timely sources of information.
              The content included on the Platform, such as text, design, graphics, logos, icons, images, audio clips, downloads, 
              interfaces, code, software, any intellectual property, and the selection and arrangement of any of these, are the 
              exclusive property of Diligent Pharma, its affiliates, its licensors and/or its content providers and is protected
               by copyright, registered trademark, and other applicable laws. Materials on the Platform or obtained through the 
               Platform may be used only for internal business purposes and not as part of any service offerings to third parties. 
               Improper use of information and improper use of any hardware or software with the intent to damage or interfere 
               with the proper working of the Platform or to surreptitiously intercept any system, data or personal information 
               from the Platform, is strictly prohibited. Provider and its Authorized Users may not interrupt or attempt to 
               interrupt the operation of the Platform in any way and Diligent Pharma reserves the right, in its sole discretion, 
               to terminate access to or use of the Platform at any time without notice. All remarks, suggestions, ideas, graphics, 
               or other information communicated to Diligent Pharma through or in relation to the Platform (other than personally 
               identifiable information or trademarked or Confidential Information) will forever be the property of Diligent Pharma. 
               Diligent Pharma will be entitled to use such submissions for commercial or other purpose without compensation, credits 
               or notice to the submitter or to any other person. Any use of information obtained from the Platform will be consistent 
               with these Terms of Use and the Privacy Policy located on the Diligent Pharma website at 
               https://www.diligentpharma.com/privacy/. Diligent Pharma owns all right, title and interest in and to the Platform 
               and all programs, code, documentation, methods, information and other materials that are a part thereof, including 
               all intellectual property rights therein.  Diligent Pharma does not grant to Provider, and Provider does not obtain 
               by virtue of these Terms of Use or otherwise, any proprietary right or interest in the Platform. Neither Provider, 
               nor anyone on its behalf shall reverse engineer or create any derivative works with respect to the Platform. Subject 
               to the licenses granted to Diligent Pharma in these Terms of Use, Provider retains all right, title, and interest in 
               and to any Provider Data.
              Intellectual property of Diligent Pharma, including trademarks, logos, and service marks as displayed on the Platform 
              are registered and unregistered trademarks of Diligent Pharma, its affiliates, its licensors, its content providers, 
              and other third parties. All such trademarks, logos, and service marks are the property of their respective owners. 
              Nothing on the Platform shall be construed as granting by implication, estoppel, or otherwise, any license or right 
              to use any trademark, logo, or service mark displayed on the Platform without the owner’s prior written permission, 
              except as otherwise described herein. Any other use of information and/or material contained on the Platform, including, 
              but not limited to, the modification, distribution, transmission, performance, broadcast, publication, licensing, reverse 
              engineering, transfer or sale of, or the creation of derivative works from, any material, information, software, or 
              services obtained from the Platform, is expressly prohibited. Diligent Pharma respects the intellectual property rights 
              of others and may remove content that it believes may infringe the intellectual property rights of others. If you believe
              that the Platform has infringed your intellectual property rights, please notify Diligent Pharma at the following address
              : Diligent Pharma Inc., 179 Nassau Street, Suite 3A, Princeton, NJ 08542 USA. USE OF THE PLATFORM IS AT THE PROVIDER’S 
              SOLE RISK. THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. DILIGENT PHARMA RESERVES THE RIGHT TO 
              RESTRICT OR TERMINATE ACCESS TO THE PLATFORM OR ANY FEATURE OR PART THEREOF AT ANY TIME. DILIGENT PHARMA EXPRESSLY
              DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO: IMPLIED WARRANTIES OF 
              MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND ANY WARRANTIES THAT MATERIALS ON THE PLATFORM ARE NONINFRINGING,
               AS WELL AS WARRANTIES IMPLIED FROM A COURSE OF PERFORMANCE OR COURSE OF DEALING; THAT ACCESS TO THE PLATFORM WILL BE 
               UNINTERRUPTED OR ERROR-FREE; THAT THE PLATFORM WILL BE SECURE; THAT THE PLATFORM WILL BE VIRUS-FREE; OR THAT INFORMATION
                ON THE PLATFORM WILL BE COMPLETE, ACCURATE, TIMELY, OR AUTHENTIC. PROVIDER IS SOLELY RESPONSIBLE FOR ANY DAMAGE TO ITS 
                COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR UPLOAD OF ANY MATERIALS. NO ADVICE OR INFORMATION, 
                WHETHER ORAL OR WRITTEN, OBTAINED FROM DILIGENT PHARMA OR THROUGH OR FROM THE PLATFORM SHALL CREATE ANY WARRANTY OF ANY
                KIND. IT IS UNDERSTOOD AND ACKNOWLEDGED THAT FULL RESPONSIBILITY FOR USE OF THE PLATFORM IS AT THE PROVIDER’S SOLE 
                RISK AND DISCRETION. DILIGENT PHARMA, ITS AFFILIATES OR BUSINESS PARTNERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
                 PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES ARISING OUT OF OR IN ANY WAY RELATED TO THE PLATFORM OR 
                 WITH THE DELAY OR INABILITY TO USE THE PLATFORM, OR FOR ANY INFORMATION OR SERVICES ADVERTISED IN OR OBTAINED THROUGH 
                 THE PLATFORM, OR OTHERWISE ARISING OUT OF THE USE OF THE PLATFORM, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY 
                 OR OTHERWISE, EVEN IF DILIGENT PHARMA OR ANY OF ITS AFFILIATES OR BUSINESS PARTNERS HAS BEEN ADVISED OF THE 
                 POSSIBILITY OF DAMAGES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF DILIGENT PHARMA AND ANY OF ITS REPRESENTATIVES 
                 FOR ANY CLAIM, LOSS OR DAMAGE ARISING OUT OF OR RELATED TO THESE TERMS OF USE EXCEED TEN THOUSAND AND 00/100 
                 ($10,000.00) DOLLARS.Provider agrees to indemnify, defend and hold harmless Diligent Pharma and its officers, 
                 directors, employees, agents, licensors, and suppliers, from and against any and all losses, liabilities, 
                 expenses, damages and costs, including reasonable attorneys’ fees and court costs, arising or resulting from 
                 Provider’s use of the Platform or any services offered or accepted through the Platform and any violation of 
                 these Terms of Use. If technical disruption of the Platform or the systems supporting it occurs due to a Provider’s 
                 or its Authorized Users’ action or inaction, the Provider agrees to be responsible for any and all losses, 
                 liabilities, expenses, damages and costs, including reasonable attorneys’ fees and court costs, arising or 
                 resulting from that disruption. Diligent Pharma reserves the right, at its own expense, to assume exclusive 
                 defense and control of any matter otherwise subject to indemnification and, in such case, Provider agrees to 
                 cooperate with Diligent Pharma in defense of such matter. Diligent Pharma will maintain reasonable administrative, 
                 physical, and technical safeguards for protection of the security, confidentiality and integrity of the Platform. 
                 “Confidential Information” means all confidential, non-public, and proprietary information which would reasonably 
                 be understood to be confidential or proprietary in nature, whether the information was marked as such and whether 
                 disclosed in oral, written, machine-readable or other form. Except as necessary to perform Diligent Pharma’s 
                 obligations or exercise its rights under these Terms of Use, Diligent Pharma will not disclose, communicate or 
                 divulge to another party, or permit the disclosure, communication or divulgence to another, or use any Confidential 
                 Information of the Provider. Diligent Pharma will (a) protect such Confidential Information by using at least the 
                 same degree of care it uses to protect its own confidential and proprietary information, but in no event less than 
                 a reasonable degree of care, (b) disclose such Confidential Information only to its employees, agents and independent 
                 contractors who each have a need to know in connection with these Terms of Use, and (c) advise each of its employees, 
                 agents and independent contractors who have access to such Confidential Information of the obligation to maintain 
                 the confidentiality of the information.  The obligations of non-use and disclosure set forth in this Section do 
                 not apply to information that is: (a) in the public domain or becomes generally known through no fault of Diligent 
                 Pharma; (b) rightfully known to Diligent Pharma without an obligation of confidence prior to disclosure hereunder; 
                 (c) lawfully obtained without obligation of confidentiality from a third party entitled to disclose such information; 
                 (d) required by law or regulation to be disclosed, but only to the extent and for the purposes of such required 
                 disclosure; or (e) disclosed in response to a valid order of a court or other governmental body, but only to the 
                 extent and for the purpose of such order.  If any disclosure is required under (d) or (e) above, the Diligent Pharma 
                 shall promptly notify the Provider of the disclosure requirement and will cooperate with the Provider, at the 
                 Provider’s expense, to obtain a protective order or otherwise limit or avoid such compelled disclosure. Diligent 
                 Pharma’s use of Provider Data as permitted by these Terms of Use shall not constitute a breach of this Section. 
                 Company represents that each Authorized Consumer is obligated under its respective agreement with Company to 
                 maintain the confidentiality of the Confidential Information of both the Company and the Provider whose Confidential 
                 Information may be accessed on the Platform. These Terms of Use shall remain in full force and effect while Provider 
                 uses the Platform (“Term”). Provider may terminate these Terms of Use upon thirty (30) days’ written notice to 
                 Diligent Pharma. Diligent Pharma may terminate Provider’s access to the Platform upon fifteen (15) days’ written 
                 notice to Provider. These Terms of Use shall be interpreted and construed in accordance with the laws of the State of 
                 New Jersey, USA without regard to principles of conflicts of the law thereof, and any disputes arising hereunder shall
                be adjudicated in the federal or state courts of New Jersey, USA to whose jurisdiction the parties hereto hereby 
                irrevocably submit and as to venue the parties hereto hereby waive all objections. These Terms of Use constitute the 
                entire agreement between Diligent Pharma and Provider and supersedes all prior agreements, negotiations, 
                representations and proposals, written and oral, relating thereto. Provider may not assign these Terms of Use or the 
                rights and obligations hereunder without the express prior written consent of Diligent Pharma, which may be withheld 
                in Diligent Pharma’s discretion. Diligent Pharma may assign these Terms of Use and its rights and obligations hereunder
                 without the consent of Provider.
                 How to Contact Us. Questions or comments about these Terms of Use or the Platform may be directed 
                 to: admin@diligentpharma.com

            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Layout
      logo={diligent_logo}
      title={
        "Submit your company information for entry into the DQP Provider Community through the Provider Onboarding Portal."
      }
      subTitle={[
        "Please sign up now for approval or login if you already have an account to continue! Upon our approval, your company will be available in our Provider Community for Sponsors on the DQP Platform.",
      ]}
      backgroundImage={signup_sidebar_img}
      children={
        <>
          {/* <Box
            component={"div"}
            sx={{
              maxWidth: "150px",
              position: "absolute",
              top: "25px",
              left: "25px",
            }}
          >
            <Link to={"/signin"}>
              <ButtonField
                text={"Login"}
                type={"button"}
                size='small'
                disabled={false}
              />
            </Link>
          </Box> */}
          <Card sx={{ ...authCardStyles, padding: "4% 10%" }}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={signUpValidationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setErrorMessage("");
                if (!captchaToken) {
                  setErrorMessage("Please check captcha!");
                  return;
                }
                register({
                  Email: values.email,
                  FirstName: values.firstname,
                  LastName: values.lastname,
                  Website: values.companyUrl,
                  PasswordHash: values.password,
                  IsInvite: inviteTokenCheckData?.data ? true : false,
                  Token: captchaToken,
                  IsAgreement:IsAgreement
                })
                  .unwrap()
                  .then((payload) => {
                    setSuccessMessage(payload.message);
                  })
                  .catch((err) => {
                    if (err.data) {
                      if (err.data?.code === "DOMAIN_ALREADY_EXIST") {
                        setPopOpen(err.data.message);
                      } else {
                        setErrorMessage(err.data.message);
                      }
                    } else {
                      setErrorMessage(err.error);
                    }
                  });
                setSubmitting(false);
              }}
              validateOnChange
            >
              {(form) => {
                return (
                  <Form>
                    {successMessage === "" ? (
                      <>
                        <Typography variant='h3' sx={authHeaderStyle}>
                          Please sign up today!
                        </Typography>
                        <Box
                          component={"div"}
                          sx={{ display: "flex", marginBottom: "20px" }}
                        >
                          <Box
                            component={"span"}
                            sx={{ width: "50%", marginRight: "10px" }}
                          >
                            <InputMaterial
                              id={"firstname"}
                              label={"First Name*"}
                              placeholder={"First Name"}
                              name={"firstname"}
                              value={form.values.firstname}
                              error={form.errors.firstname as string}
                              touched={form.touched.firstname}
                              disabled={Boolean(
                                inviteTokenCheckData?.data.FirstName
                              )}
                            />
                          </Box>
                          <Box
                            component={"span"}
                            sx={{ width: "50%", marginLeft: "10px" }}
                          >
                            <InputMaterial
                              id={"lastname"}
                              label={"Last Name*"}
                              placeholder={"Last Name"}
                              name={"lastname"}
                              value={form.values.lastname}
                              error={form.errors.lastname as string}
                              touched={form.touched.lastname}
                              disabled={Boolean(
                                inviteTokenCheckData?.data.LastName
                              )}
                            />
                          </Box>
                        </Box>
                        <Box component={"div"} sx={{ marginBottom: "20px" }}>
                          <InputMaterial
                            id={"email"}
                            label={"Email*"}
                            placeholder={"Email"}
                            name={"email"}
                            value={form.values.email}
                            error={form.errors.email as string}
                            touched={form.touched.email}
                            disabled={Boolean(inviteTokenCheckData?.data.Email)}
                          />
                        </Box>
                        <Box component={"div"} sx={{ marginBottom: "20px" }}>
                          <InputMaterial
                            id={"companyUrl"}
                            label={"Company Url*"}
                            placeholder={"Company url"}
                            name={"companyUrl"}
                            value={form.values.companyUrl}
                            error={form.errors.companyUrl as string}
                            touched={form.touched.companyUrl}
                            disabled={Boolean(
                              inviteTokenCheckData?.data.Website
                            )}
                          />
                        </Box>
                        <Box component={"div"} sx={{ marginBottom: "20px" }}>
                          <PasswordField
                            label={"Password*"}
                            id={"password"}
                            placeholder={"New password"}
                          />
                        </Box>

                        <Box component={"div"} sx={{ marginBottom: "20px" }}>
                          <PasswordField
                            label={"Confirm Password*"}
                            id={"confirmPassword"}
                            placeholder={"Confirm your password"}
                          />
                        </Box>
                            
                        <Box  
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          flexDirection:'row',
                        }}>
                         <input type="checkbox" onChange={(e)=>{
                          setIsAgreement(!IsAgreement)}
                         } 
                         style={{marginBottom:'7px',border:"1px solid red"}}
                         required/>
                          <Label
                                check
                                style={{
                                  fontWeight: "300",
                                  fontSize: "0.9rem",
                                  marginTop:'-1px',
                                  marginLeft:'5px',
                                  color: "#130D4F"
                                }}
                              >
                                
                                By clicking this box you agree to our{" "}
                                <Label
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    toggleIsActive()
                                  }}
                                >
                                  Terms and Conditions.
                                </Label>
                              </Label>
                            </Box>

                        <Box
                          component={"div"}
                          sx={{
                            marginBottom: "20px",
                            "& div": {
                              margin: "auto",
                              width: "100%",
                              ".rc-anchor-pt": {
                                textAlign: "center",
                              },
                            },
                          }}
                        >
                          <ReCAPTCHA
                            sitekey={
                              process.env
                                .REACT_APP_GOOGLE_RECAPTCHA_KEY as string
                            }
                            onChange={onCaptchaChange}
                          />
                        </Box>
                        <Box component={"div"} sx={{ marginBottom: "20px" }}>
                          <ButtonField
                            text={"Sign Up"}
                            type={"submit"}
                            size='large'
                            disabled={form.isSubmitting}
                          />
                        </Box>
                      </>
                    ) : (
                      <Typography
                        variant='h6'
                        sx={{
                          marginBottom: "24px",
                          color: "green",
                          textAlign: "center",
                        }}
                      >
                        {successMessage}
                      </Typography>
                    )}

                    <Typography
                      variant='body1'
                      sx={{
                        textAlign: "center",
                        "& a": { textDecoration: "none" },
                      }}
                    >
                      Already have an account? Click{" "}
                      <Link to={"/signin"}>
                        <strong> here</strong>
                      </Link>{" "}
                      to Sign In.
                    </Typography>
                  </Form>
                );
              }}
            </Formik>
            {errorMessage && (
              <AppSnackBar
                open={true}
                message={errorMessage}
                type={"error"}
                time={5000}
              />
            )}
            {/* {successMessage && (
            <AppSnackBar
              open={true}
              message={successMessage}
              type={"success"}
            />
          )} */}
            <AppLoader open={isLoading || inviteLoading} />
            <Dialog
              sx={{ "& .MuiDialog-paper": { width: "80%" } }}
              // maxWidth='xs'
              open={popOpen !== ""}
            >
              <DialogTitle>Error</DialogTitle>
              <DialogContent dividers>{popOpen}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setPopOpen("")}
                  variant='contained'
                  sx={{ ...buttonStyle }}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          </Card>

          {isActive && 
            <DialogBox 
              isCloseEnable
              open={isActive}
              setOpen = {setIsActive}
              body={TermsConditionBody}
            />
          }
        </>
      }
    />
  );
}
