import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiSlice } from "../../app/api/apiSlice";
import { useLogoutMutation } from "../../features/auth/authApiSlice";
import {
  getState,
  logOut,
  setOnboardingStatus,
} from "../../features/auth/authSlice";
import Layout from "../reuseable/layout";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.svg";
import { useGetProviderDataQuery } from "../../features/onboardingPortal/onboardingPortalApiSlice";
import AppLoader from "../reuseable/appLoader";
import AppSnackBar from "../reuseable/appSnackBar";

export default function SubmitConfirmation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const { token, status } = useSelector(getState);
  const [errorMessage, setErrorMessage] = useState("");
  const { data: providerData, isLoading, error } = useGetProviderDataQuery({});

  useEffect(() => {
    if (error && "data" in error) {
      setErrorMessage(error.data.message);
    } else {
      if (providerData?.data) {
        dispatch(
          setOnboardingStatus({
            status: providerData.data.Status,
          })
        );
        if (
          providerData.data?.IsPlatformReady &&
          providerData.data.IsPlatformReady
        ) {
          // navigate("/wizard/submit");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("status");
          window.location.replace(
            process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token
          );
        }
      }
    }
  }, [dispatch, error, navigate, providerData, token]);

  useEffect(() => {
    if (
      [
        process.env.REACT_APP_USER_DEFAULT_WIZARD_STATUS,
        process.env.REACT_APP_USER_ONBOARD_APPROVED_STATUS,
      ].includes(status)
    ) {
      navigate("/wizard");
    }
    if (
      [
        process.env.REACT_APP_USER_INVITE_PENDING_REVIEW_STATUS,
        process.env.REACT_APP_USER_ONBOARD_PENDING_STATUS,
        process.env.REACT_APP_USER_DEFAULT_STATUS,
      ].includes(status)
    ) {
      navigate("/onboarding");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // const handleClickToWizard = () => {
  //   // console.log(token)
  //   localStorage.setItem("user", "Wizard");
  //   localStorage.setItem("status", "Approved");
  //   dispatch(
  //     setCredentials({
  //       user: "Wizard",
  //       accessToken: token,
  //       status: "Approved"
  //     })
  //   );
  // }

  const handleLogout = () => {
    logout({})
      .unwrap()
      .then((payload) => {
        if (payload.data) {
          dispatch(apiSlice.util.resetApiState());
          dispatch(logOut());
          navigate("/signin");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      <Layout
        logo={diligent_logo}
        title={
          "Welcome to Diligent Qualification Platform Provider Onboarding Portal!"
        }
        subTitle={[
          "The Provider Onboarding Portal provides a simple process for submission of your company information for entry into the DQP Provider Community.",
          "Upon approval, your company information will be entered into our Provider Community and available to the Sponsors on the DQP Platform.  Please sign in to continue or sign up now for your free account.",
        ]}
        backgroundImage={signup_sidebar_img}
        children={
          <Box
            component={"div"}
            sx={{
              maxWidth: "503px",
              minHeight: "30vh",
              display: "flex",
              margin: "auto",
            }}
          >
            {isLoading ? (
              <AppLoader open={true} />
            ) : (
              <Box
                component={"div"}
                sx={{ textAlign: "center", margin: "auto" }}
              >
                <Typography variant='h5'>
                  Thank you for submitting your request to join the Diligent
                  Qualification Platform Provider Community!
                </Typography>
                <Typography variant='body1'>
                  We'll be in touch soon with next steps.
                </Typography>
                {token && (
                  <Button
                    variant='contained'
                    onClick={() => handleLogout()}
                    // onClick={handleClickToWizard}
                    sx={{
                      textTransform: "none",
                      borderRadius: "6px",
                      height: "2rem",
                      fontSize: "1rem",
                      marginTop: "20px",
                    }}
                  >
                    Close
                  </Button>
                )}
              </Box>
            )}
          </Box>
        }
      />
      {errorMessage && (
        <AppSnackBar
          open={true}
          time={2000}
          message={errorMessage}
          type={"error"}
        />
      )}
    </Box>
  );
}
