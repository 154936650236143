import { InputLabel, FormControl, TextField } from "@mui/material";
import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormikContext } from "formik";
import { FieldType, materialInputStyles } from "./InputMaterial";

interface DatePickerMaterialProps {
  id: string;
  label: string;
  formName?: string;
  fieldValue: string;
  error: string | undefined;
  touched: any;
}
export default function DatePickerMaterial({
  id,
  label,
  formName,
  fieldValue = "",
  error,
  touched,
}: DatePickerMaterialProps) {
  const form = useFormikContext<FieldType>();
  const [value, setValue] = React.useState<string | number | null>(
    fieldValue
      ? format(new Date(parseInt(fieldValue), 11, 11), "MM/dd/yyyy")
      : null
  );
  const handleChange = (e: string | number | null) => {
    const changedValue = e
      ? new Date(e).getFullYear()
      : "";
    form.setFieldValue(`${formName}.${id}`, changedValue);
    setValue(e);
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <InputLabel
          sx={{
            fontWeight: 700,
            color: touched && Boolean(error) ? "red" : "",
          }}
        >
          {label}
        </InputLabel>
        <FormControl fullWidth variant='standard'>
          <DatePicker
            disableFuture
            orientation='portrait'
            label=''
            inputFormat='yyyy'
            views={["year"]}
            openTo='year'
            value={value}
            onChange={(e) => handleChange(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder= "YYYY"
                sx={materialInputStyles}
                margin='dense'
                helperText={touched && error ? error : ""}
                error={touched && Boolean(error)}
              />
            )}
          />
        </FormControl>
      </LocalizationProvider>
    </>
  );
}
