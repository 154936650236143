import * as yup from "yup";

export const step1Schema = yup.object().shape({
  step1: yup.object().shape({
    ProviderName: yup
      .string()
      .min(3, "Min 3 letters please.")
      .required("Company Name Required"),
    Website: yup
      .string()
      .required("Company Url Required"),
    // ProviderFileName: yup
    //   .string()
    //   .min(3, "Min 3 letters please.")
    //   .required("Short Company Name Required"),
    Address: yup.string().max(100, "Max 100 chars").required("Address Line1 Required"),
    Address2: yup.string().max(100, "Max 100 chars"),
    Country: yup.string().max(30, "Max 30 chars").required("Country Required"),
    State: yup.string().max(30, "Max 30 chars"),
    ZipCode: yup.string().matches(/^[A-Za-z0-9]{3,10}$/g,"Not a valid postal code").required("Postal Code Required"),
    City: yup.string().max(30, "Max 30 chars").required("City Required"),
    // @ts-ignore:next-line
    PhoneNumber: yup.string().matches(/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm, "Not a valid phone number").required("Phone Required"),
    Description: yup.string().max(2000, "Max 2000 chars"),
  }),
});

export const step2Schema = yup.object().shape({
  step2: yup.object().shape({
    IsPublic: yup.boolean(),
    Ticker: yup.string(),
    YearFounded: yup.string().matches(/^(19|20)\d{2}$/,'Enter a valid year'),
    NumberOfEmployees: yup.string(),
    Certifications: yup.array().of(yup.string()),
    OtherCertifications: yup.array().of(yup.string()),
    Classifications: yup.array().of(yup.string()).min(1,'Please select a Diversity Classification or None if not applicable.'),
  }),
});

export const step3Schema = yup.object().shape({
  step3: yup.object().shape({
    PharmaSponsorsServed: yup.string(),
    GeographicCoverage: yup.array().of(yup.string()).min(1,'Please select a Geographic Regions Served.'),
    TherapeuticAreas: yup.array().of(yup.string()).min(1,'Please select a Therapeutic Areas Supported or None if not applicable.'),
    OtherTherapeuticAreas: yup.array().of(yup.string()),
    ProviderCapabilities: yup.array().of(yup.string()).min(1,'Please select a Capabilities/Services Provided.'),
    OtherCapabilitiesOrServices: yup.array().of(yup.string()),
    ServiceComplianceStatuses: yup.array().of(yup.string()).min(1,'Please select a Service Compliance Types or None if not applicable.'),
  }),
});
