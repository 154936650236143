import React from "react";
import {
  TextField,
  Chip,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import { FieldType, materialInputStyles } from "./InputMaterial";
import Downshift from "downshift";
import { useFormikContext } from "formik";
interface MultiValueInputProps {
  label: string;
  placeholder: string;
  id: string;
  formName: string;
  options: Array<string>;
  error: string | undefined;
  touched: any;
}
export default function MultiValueInput({
  label,
  placeholder,
  id,
  formName,
  options: prevValue,
  error,
  touched,
}: MultiValueInputProps) {
  const limitTags: number = 5;
  const form = useFormikContext<FieldType>();
  const name = `${formName}.${id}`;
  const [inputValue, setInputValue] = React.useState<string>("");
  const [selectedItem, setSelectedItem] =
    React.useState<Array<string>>(prevValue);

  function handleKeyDown(evt: { key: string; preventDefault: () => void }) {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = inputValue.trim();
      if (value && !selectedItem.includes(value)) {
        setSelectedItem([...selectedItem, inputValue]);
        form.setFieldValue(name, [...selectedItem, inputValue]);
        setInputValue("");
      }
      if (evt.key === "Enter") {
        const newSelectedItem = [...selectedItem];
        const duplicatedValues = newSelectedItem.indexOf(value.trim());
        if (duplicatedValues !== -1) {
          setInputValue("");
          return;
        }
        if (!value.replace(/\s/g, "").length) return;

        newSelectedItem.push(value.trim());
        setSelectedItem(newSelectedItem);
        form.setFieldValue(name, newSelectedItem);
        setInputValue("");
      }
    }

    if (selectedItem.length && !inputValue.length && evt.key === "Backspace") {
      const newVal = selectedItem.slice(0, selectedItem.length - 1);
      setSelectedItem(newVal);
      form.setFieldValue(name, newVal);
    }
  }

  function handleChange(item: any) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
    form.setFieldValue(name, newSelectedItem);
  }

  const handleDelete = (item: string) => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
    form.setFieldValue(name, newSelectedItem);
  };

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setInputValue(event.target.value);
  }
  return (
    <React.Fragment>
      <Downshift
        id='downshift-multiple'
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({ getInputProps }) => {
          const { onBlur, onFocus } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });
          return (
            <div>
              <InputLabel
                sx={{
                  fontWeight: 500,
                  color: touched && Boolean(error) ? "red" : "",
                }}
              >
                {label}
              </InputLabel>
              <FormControl sx={{ height:'fit-content', "& .MuiTextField-root": { marginTop: 0 } }} variant='standard' fullWidth>
                <TextField
                  variant='outlined'
                  placeholder={placeholder}
                  value={inputValue}
                  InputProps={{
                    startAdornment:
                      selectedItem.length > limitTags ? (
                        <Box sx={{ display: "flex", flexWrap:'wrap', padding: "3px 10px 3px 0px" }}>
                          {
                            selectedItem.slice(0, limitTags).map((item) => (
                              <Chip
                                key={item}
                                tabIndex={-1}
                                label={item}
                                onDelete={handleDelete(item)}
                                sx={{margin: '3px'}}
                              />
                            ))
                          } 
                          <Typography
                            variant={"body1"}
                            sx={{ margin: "auto", padding: "3px" }}
                          >{`+${selectedItem.length - limitTags}`}</Typography>
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", maxWidth: '90%', flexWrap:'wrap', padding: "3px 10px 3px 0px" }}>
                          {
                            selectedItem.map((item) => (
                              <Chip
                                key={item}
                                tabIndex={-1}
                                label={item}
                                onDelete={handleDelete(item)}
                                sx={{margin: '3px'}}
                              />
                            ))
                          }
                        </Box>
                      ),
                    onBlur,
                    onChange: (event) => {
                      handleInputChange(event);
                      // onChange(event);
                    },
                    onFocus,
                  }}
                  onKeyDown={handleKeyDown}
                  sx={{
                    ...materialInputStyles,
                    "& .MuiInputBase-root": { height: 'auto', minHeight: '3.2em' },
                    "& .MuiOutlinedInput-root": { paddingLeft: "7px", display: selectedItem.length > 1 ? 'block': 'flex' },
                    "& .MuiOutlinedInput-input": { padding: "7.5px 4px 7.5px 7.5px", boxSizing: 'inherit', minHeight: '2.8em' },
                  }}
                  helperText={touched && error ? error : ""}
                  error={touched && Boolean(error)}
                  margin='dense'
                />
              </FormControl>
            </div>
          );
        }}
      </Downshift>
    </React.Fragment>
  );
}
